import React from "react";
import { useEffect, useState } from "react";
import "./index.css";
const App = () => {
  useEffect(() => {
    window.location.replace(
      "https://new.bambinocampones.com.br" + window.location.pathname
    );
  }, [window]);
  const [isOpen, setIsOpen] = useState(false);
  const navigate = (url) => {
    setIsOpen(false);
    window.location.replace("https://new.bambinocampones.com.br" + url);
  };
  return (
    <>
      <span className="loader" />
      <header
        id="header"
        className="header header-layout-type-header-2rows  headerDesktop"
      >
        <div className="header-nav tm-enable-navbar-hide-on-scroll">
          <div className="header-nav-wrapper navbar-scrolltofixed">
            <div
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                zIndex: 99999,
              }}
              className="menuzord-container header-nav-container"
            >
              <div className="container position-relative">
                <div className="row header-nav-col-row">
                  <div className="col-sm-auto align-self-center">
                    <a href={"/"} passHref>
                      <a className="menuzord-brand site-brand">
                        <img
                          className="logo-default logo-1x"
                          src="/images/logo-wide.png"
                          alt="Logo"
                        />
                        <img
                          className="logo-default logo-2x retina"
                          src="/images/logo-wide@2x.png"
                          alt="Logo"
                        />
                      </a>
                    </a>
                  </div>
                  <div className="col-sm-auto ms-auto pr-0 align-self-center">
                    <nav
                      id="top-primary-nav"
                      className="menuzord theme-color2"
                      data-effect="fade"
                      data-animation="none"
                      data-align="right"
                    >
                      <ul id="main-nav" className="menuzord-menu">
                        <li className="active">
                          <a href={"/"} passHref>
                            Home
                          </a>
                        </li>

                        <li>
                          <a href={"/#news"} passHref>
                            Notícias
                          </a>
                        </li>
                        <li>
                          <a href={"/cardapio"} passHref>
                            Cardápios
                          </a>
                        </li>
                        <li>
                          <a href={"/galery"} passHref>
                            Fotos
                          </a>
                        </li>
                        <li>
                          <a href={"/galery-videos"} passHref>
                            Vídeos
                          </a>
                        </li>
                        <li>
                          <a href={"#footer"} passHref>
                            Contato
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
                <div className="row d-block d-xl-none">
                  <div className="col-12">
                    <nav
                      id="top-primary-nav-clone"
                      className="menuzord d-block d-xl-none default menuzord-color-default menuzord-border-boxed menuzord-responsive"
                      data-effect="slide"
                      data-animation="none"
                      data-align="right"
                    >
                      <ul
                        id="main-nav-clone"
                        className="menuzord-menu menuzord-right menuzord-indented scrollable"
                      ></ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <header className="headerMobile">
        <a href={"/"} passHref>
          <a className="menuzord-brand site-brand">
            <img
              className="logo-default logo-1x"
              src="/images/logo-wide.png"
              alt="Logo"
            />
          </a>
        </a>
        <div>
          <img
            onClick={() => setIsOpen(true)}
            style={{ width: 34, height: 34 }}
            src="/images/hamburger.png"
            alt=""
          />
        </div>
      </header>

      <div className={"style-headerMobile"}>
        <div style={{ width: isOpen ? 250 : 0 }} className={"styles-sidenav"}>
          <div className={"styles-rowExitView2"}>
            <img
              className="logo-default logo-1x"
              src="/images/logo-wide.png"
              alt="Logo"
            />
          </div>
          <div className={"styles-collumn2"}>
            <span onClick={() => navigate("/")} className={"styles-textmenu"}>
              Página inicial
            </span>
            <span
              onClick={() => navigate("/#news")}
              className={"styles-textmenu"}
            >
              Notícias
            </span>
            <span
              onClick={() => navigate("/cardapio")}
              className={"styles-textmenu"}
            >
              Cardápios
            </span>
            <span
              onClick={() => navigate("/galery")}
              className={"styles-textmenu"}
            >
              Fotos
            </span>
            <span
              onClick={() => navigate("/galery-videos")}
              className={"styles-textmenu"}
            >
              Vídeos
            </span>
            <span
              onClick={() => navigate("#footer")}
              className={"styles-textmenu"}
            >
              Contato
            </span>
          </div>
          <br />
        </div>
      </div>
      {isOpen && (
        <div
          onClick={() => setIsOpen(false)}
          style={{
            zIndex: "999999",
            backgroundColor: "rgba(0,0,0,0.4)",
            width: "100vw",
            height: "100vh",
            position: "fixed",
          }}
        />
      )}
      <span data-nosnippet>
        Escola de educação infantil - Canoas/RS - construindo o futuro e
        formando pequenos cidadãos! escola, infantil, educação, canoas,
        criançãs, ensino, berçário, maternal, pré, canoas, infantil, educação,
        bambino, camponês, escola, criançã, particular
      </span>
    </>
  );
};
export default App;
